
































































import { Component, Vue } from 'vue-property-decorator';
import LinkStrategyModerateHiringViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-hiring-view-model';

@Component({ name: 'LinkStrategyModerateHiring' })
export default class LinkStrategyModerateHiring extends Vue {
  strategy_hiring_view_model = Vue.observable(new LinkStrategyModerateHiringViewModel(this));

  created() {
    this.strategy_hiring_view_model.initialize();
  }
}
